import React from "react";
import { Alert, Button } from "react-bootstrap";
import BusySpinner from "common/components/BusySpinner";
import SiteSelector from "common/components/SiteSelector";
import MultiSearchBoxSelector from "common/components/MultiSearchBoxSelector/MultiSearchBoxSelector";
import BulkSiteUnassignmentResultPool from "./BulkSiteUnassignmentResultPool";

/*
 * Drill down first by Site then by User to select assignments to unassign.
 */
class BulkSiteUnassignBySiteTab extends React.Component {
  state = {
    userSearchboxVal: ""
  };

  renderAssignedSites = () => (
    <React.Fragment>
      <h4 className="BulkAssgn__Label">Select Sites</h4>
      <SiteSelector
        companyId={this.props.companyId}
        selectSiteFunc={this.props.selectSiteFunc}
        showMySitesToggle={false}
        handleSelect={this.props.storeSiteToUnassign}
      />
    </React.Fragment>
  );

  renderAssignedPersons = iconFunc => (
    <React.Fragment>
      <h4 className="BulkAssgn__Label">Select Persons</h4>
      <MultiSearchBoxSelector
        dataSources={this.props.assignedPersonsDS}
        fields={["username", "email"]}
        minSearchLen={0}
        closeOnSelect={false}
        handleSelect={this.props.storeSitePersonToUnassign}
        onChange={v => this.setState({ userSearchboxVal: v })}
        value={this.state.userSearchboxVal || ""}
        iconFunc={iconFunc}
      />
    </React.Fragment>
  );

  render() {
    const {
      iconFunc,
      sitePersonsToUnassignObjects,
      assignedSitesDS,
      assignedPersonsDS,
      companySites,
      unassignError,
      isFetching,
      handleSubmitUnassign,
      handleCancel,
      undoUnassign
    } = this.props;

    return (
      <div className="BulkAssgn__grid">
        <div />

        <div className="BulkAssgn__SelectSites">
          {assignedSitesDS && this.renderAssignedSites()}
          {assignedPersonsDS &&
            !!assignedPersonsDS[0].data.length &&
            this.renderAssignedPersons(iconFunc)}
        </div>

        <div className="BulkAssgn__CentralBar" />

        <BulkSiteUnassignmentResultPool
          sitePersonsToUnassignObjects={sitePersonsToUnassignObjects}
          undoUnassign={undoUnassign}
          companySites={companySites}
        />

        <div className="BulkAssgn__Buttons">
          {!!unassignError && <Alert variant="danger">{unassignError}</Alert>}
          <Button className="BulkAssgn__CancelButton" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleSubmitUnassign}
            disabled={
              isFetching ||
              !sitePersonsToUnassignObjects ||
              !Object.keys(sitePersonsToUnassignObjects).length
            }
          >
            {isFetching ? (
              <span>
                <BusySpinner inButton={true} />
                Unassigning Users from Sites
              </span>
            ) : (
              "Unassign Users from Sites"
            )}
          </Button>
        </div>
      </div>
    );
  }
}

export default BulkSiteUnassignBySiteTab;
