import { put, take, race, takeEvery, spawn } from 'redux-saga/effects';
import log from 'loglevel';
import { runSemaphore } from "common/helpers/semaphore";

import { 
  SUBSCRIBE_REPORT_SUBMIT, UNSUBSCRIBE_REPORT_SUBMIT, SUBSCRIBE_REPORT_UPDATE_SUBMIT,
  SUBSCRIBE_REPORT_SUCCESS, UNSUBSCRIBE_REPORT_SUCCESS, SUBSCRIBE_REPORT_UPDATE_SUCCESS,
  SUBSCRIBE_REPORT_FAILURE, UNSUBSCRIBE_REPORT_FAILURE, SUBSCRIBE_REPORT_UPDATE_FAILURE,
  USER_REPORTSUBS_SUBMIT
}
  from 'common/dataRetrieval/ReportsDuck'

import {
  USER_REPORTSUBS_FAILURE,
  USER_REPORTSUBS_SUCCESS,
} from '../../dataRetrieval/ReportsDuck';
import {
  REPORTSUBSCRIPTION_SUBMIT,
  REPORTSUBSCRIPTION_SUCCESS,
  REPORTSUBSCRIPTION_FAILURE,
} from './ProfileDuck'

const SEMAPHORE_NAME = 'USERLISTASSIGN_REPORTSUBSCRIPTIONS';

export function* saveReportSubscriptionSaga ({data}) {
  try {

    // short circuit to not wait for semaphore which will never happen
    if (
      data.companiesToSubscribeTo && data.companiesToSubscribeTo.length === 0 &&
      data.companiesWithSitesToSubscribeTo && data.companiesWithSitesToSubscribeTo.length === 0 &&
      data.customReportsToSubscribeTo && data.customReportsToSubscribeTo.length === 0 &&
      data.companiesToUnsubscribeFrom && data.companiesToUnsubscribeFrom.length === 0 &&
      data.companiesWithSitesToUnsubscribeFrom && data.companiesWithSitesToUnsubscribeFrom.length === 0 &&
      data.customReportsToUnsubscribeFrom && data.customReportsToUnsubscribeFrom.length === 0 &&
      data.companiesToUpdate && data.companiesToUpdate.length === 0 &&
      data.companiesWithSitesToUpdate && data.companiesWithSitesToUpdate.length === 0 &&
      data.customReportsToUpdate && data.customReportsToUpdate.length === 0 &&

      data.dailyThreatsToSubscribeTo && data.dailyThreatsToSubscribeTo.length === 0 &&
      data.dailyThreatsToUnsubscribeFrom && data.dailyThreatsToUnsubscribeFrom.length === 0 &&
      data.dailyThreatsToUpdate && data.dailyThreatsToUpdate.length === 0 &&
      data.dailyThreatsWithSitesToSubscribeTo && data.dailyThreatsWithSitesToSubscribeTo.length === 0 &&
      data.dailyThreatsWithSitesToUnsubscribeFrom && data.dailyThreatsWithSitesToUnsubscribeFrom.length === 0 &&
      data.dailyThreatsWithSitesToUpdate && data.dailyThreatsWithSitesToUpdate.length === 0 &&

      data.sitesToSubscribeTo && data.sitesToSubscribeTo.length === 0 &&
      data.sitesToUnsubscribeFrom && data.sitesToUnsubscribeFrom.length === 0 &&
      data.sitesToUpdate && data.sitesToUpdate.length === 0
    ){
      yield put({ type: REPORTSUBSCRIPTION_SUCCESS })
      return;
    }

    function* subscribeFunc (companyIds, userId, reportType){
      for (let cid of companyIds) {
        yield put({
          type: SUBSCRIBE_REPORT_SUBMIT,
          userId: data.userId,
          companyId: cid.companyId,
          sendIfNecessary: cid.sendIfNecessary,
          reportType
        })
      }
    }

    yield subscribeFunc(data.companiesToSubscribeTo, data.userId, 'company');
    yield subscribeFunc(data.companiesWithSitesToSubscribeTo, data.userId, 'companyWithSites');
    yield subscribeFunc(data.customReportsToSubscribeTo, data.userId, 'customReport');
    yield subscribeFunc(data.dailyThreatsToSubscribeTo, data.userId, 'dailyThreat');
    yield subscribeFunc(data.dailyThreatsWithSitesToSubscribeTo, data.userId, 'dailyThreatWithSites');

    function* unSubscribeFunc (companyIds, userId, reportType){
      for (let cid of companyIds) {
        yield put({
          type: UNSUBSCRIBE_REPORT_SUBMIT,
          userId: userId,
          companyId: cid,
          reportType
        })
      }
    }

    yield unSubscribeFunc(data.companiesToUnsubscribeFrom, data.userId, 'company');
    yield unSubscribeFunc(data.companiesWithSitesToUnsubscribeFrom, data.userId, 'companyWithSites');
    yield unSubscribeFunc(data.customReportsToUnsubscribeFrom, data.userId, 'customReport');
    yield unSubscribeFunc(data.dailyThreatsToUnsubscribeFrom, data.userId, 'dailyThreat');
    yield unSubscribeFunc(data.dailyThreatsWithSitesToUnsubscribeFrom, data.userId, 'dailyThreatWithSites');

    function* updateFunc (subscriptionsToUpdate, companyId, userId, reportType){
      for (let subscription of subscriptionsToUpdate) {
        yield put({
          type: SUBSCRIBE_REPORT_UPDATE_SUBMIT,
          userId: userId,
          companyId: companyId,
          subscriptionId: subscription.id,
          sendIfNecessary: subscription.sendIfNecessary
        })
      }
    }

    yield updateFunc(data.companiesToUpdate, data.companyId, data.userId);
    yield updateFunc(data.companiesWithSitesToUpdate, data.companyId, data.userId);
    yield updateFunc(data.customReportsToUpdate, data.companyId, data.userId);
    yield updateFunc(data.dailyThreatsToUpdate, data.companyId, data.userId);
    yield updateFunc(data.dailyThreatsWithSitesToUpdate, data.companyId, data.userId);

    for (let sid of data.sitesToSubscribeTo) {
      const [siteId, siteReportType] = sid.siteIdAndType.split('-')
      yield put({
        type: SUBSCRIBE_REPORT_SUBMIT,
        userId: data.userId,
        siteId,
        companyId: data.companyId,
        siteReportType,
        sendIfNecessary: sid.sendIfNecessary
      })
    }

    for (let subscription of data.sitesToUpdate) {
      yield put({
        type: SUBSCRIBE_REPORT_UPDATE_SUBMIT,
        userId: data.userId,
        companyId: data.companyId,
        subscriptionId: subscription.id,
        sendIfNecessary: subscription.sendIfNecessary
      })
    }

    for (let sid of data.sitesToUnsubscribeFrom) {
      const [siteId, reportType] = sid.split('-')
      yield put({
        type: UNSUBSCRIBE_REPORT_SUBMIT,
        userId: data.userId,
        companyId: data.companyId,
        siteId,
        reportType
      })
    }

    yield take (`${SEMAPHORE_NAME}_SEMAPHORE_ZERO`);
    log.debug(`${SEMAPHORE_NAME}_SEMAPHORE_ZERO`);

    yield put({
      type: USER_REPORTSUBS_SUBMIT,
      userId: data.userId,
      userType: data.userType
    });

    // wait for either of these actions to appear:
    yield race({
      success: take(USER_REPORTSUBS_SUCCESS),
      failure: take(USER_REPORTSUBS_FAILURE)
    });
    yield put({ type: REPORTSUBSCRIPTION_SUCCESS })

  } catch (error) {
    log.warn('REPORTSUBSCRIPTION_FAILURE', error.message)

    // 'payload' is the redux-form-submit-saga object that is given wholesale to
    // redux-form's SubmissionError
    yield put({ type: REPORTSUBSCRIPTION_FAILURE,
      message: error.message, 
    })
  }
}

// listen for actions of type USEREDIT_SUBMIT and use them
export default function* ownReportSubscriptionSaga () {
  yield spawn (runSemaphore, SEMAPHORE_NAME,
    [SUBSCRIBE_REPORT_SUBMIT,UNSUBSCRIBE_REPORT_SUBMIT,SUBSCRIBE_REPORT_UPDATE_SUBMIT],
    [SUBSCRIBE_REPORT_SUCCESS, UNSUBSCRIBE_REPORT_SUCCESS, SUBSCRIBE_REPORT_UPDATE_SUCCESS,
      SUBSCRIBE_REPORT_FAILURE, UNSUBSCRIBE_REPORT_FAILURE, SUBSCRIBE_REPORT_UPDATE_FAILURE]
  );
  yield takeEvery(REPORTSUBSCRIPTION_SUBMIT, saveReportSubscriptionSaga)
}
