export const INVITEPERSON_OPEN = 'INVITEPERSON_OPEN';
export const INVITEPERSON_CLOSE = 'INVITEPERSON_CLOSE';
export const INVITEPERSON_SUBMIT = 'INVITEPERSON_SUBMIT';
export const INVITEPERSON_SUCCESS = 'INVITEPERSON_SUCCESS';
export const INVITEPERSON_FAILURE = 'INVITEPERSON_FAILURE';

export const INVITEAGAIN_SUBMIT = 'INVITEAGAIN_SUBMIT';
export const INVITEAGAIN_SUCCESS = 'INVITEAGAIN_SUCCESS';
export const INVITEAGAIN_FAILURE = 'INVITEAGAIN_FAILURE';

export const closeInvitePersonModal = () => ({
  type: INVITEPERSON_CLOSE
});

export const openInvitePersonModal = () => ({
  type: INVITEPERSON_OPEN
});

export const invitePersonAgain = id => ({
  type: INVITEAGAIN_SUBMIT,
  id
});

const initialState = {
  showModal: false,
  isFetching: false,
  errorMessage: undefined
};

const invitePersonReducer = (state = initialState, action) => {
  switch (action.type) {
    case INVITEPERSON_OPEN:
      return {
        ...state,
        showModal: true
      };
    case INVITEPERSON_CLOSE:
      return initialState;

    case INVITEPERSON_SUBMIT:
      return {
        ...state,
        isFetching: true,
        errorMessage: undefined
      };
    case INVITEPERSON_SUCCESS:
      return initialState;

    case INVITEPERSON_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage:
          typeof action.payload?._error === 'string' ? action.payload?._error : undefined
      };
    default:
      return state;
  }
};

export default invitePersonReducer;
