import immutable from "object-path-immutable";

export const BULK_ADD_USER = "BULK_ADD_USER";
export const BULK_DEL_USER = "BULK_DEL_USER";
export const BULK_RESET = "BULK_RESET";
export const BULK_STORE_SITETOUNASSIGN = "BULK_STORE_SITETOUNASSIGN";
export const BULK_STORE_SITEPERSONTOUNASSIGN =
  "BULK_STORE_SITEPERSONTOUNASSIGN";
export const BULK_UNDO_SITEPERSONUNASSIGN = "BULK_UNDO_SITEPERSONUNASSIGN";
export const BULK_SET_TABLE_SORT_KEY = "BULK_SET_TABLE_SORT_KEY";
export const BULK_ENABLE_REPORT_ASSIGNMENT = "BULK_ENABLE_REPORT_ASSIGNMENT";
export const BULK_SET_REPORTTYPE = "BULK_SET_REPORTTYPE";
export const BULK_SET_REPORT_SUBTYPE = "BULK_SET_REPORT_SUBTYPE";

export const storeSiteToUnassign = id => ({
  type: BULK_STORE_SITETOUNASSIGN,
  id
});

export const storeSitePersonToUnassign = (id, siteId) => ({
  type: BULK_STORE_SITEPERSONTOUNASSIGN,
  id,
  siteId
});

export const undoUnassign = (sid, pid) => ({
  type: BULK_UNDO_SITEPERSONUNASSIGN,
  sid,
  pid
});

export const addUser = id => ({
  type: BULK_ADD_USER,
  id
});

export const delUser = id => ({
  type: BULK_DEL_USER,
  id
});

export const resetUsers = id => ({
  type: BULK_RESET
});

export const setTableSortKey = (key, direction) => ({
  type: BULK_SET_TABLE_SORT_KEY,
  key,
  direction
});

export const enableReportAssignment = enabled => ({
  type: BULK_ENABLE_REPORT_ASSIGNMENT,
  enabled
});

export const setReportsToAssign = (reportType, checked) => ({
  type: BULK_SET_REPORTTYPE,
  reportType,
  checked
});

export const setReportSubtypeChange = (reportType, sendIfNecessary) => ({
  type: BULK_SET_REPORT_SUBTYPE,
  reportType, 
  sendIfNecessary
});

const initialState = {
  siteIDs: [],
  userIDs: [],
  siteToUnassign: undefined,
  sitePersonsToUnassign: {},
  tableSortKey: "name",
  tableSortDirection: 1,
  reports: {
    site: { type: "site", checked: false, sendIfNecessary: true },
    threeDaySummary: {
      type: "threeDaySummary",
      checked: false,
      sendIfNecessary: true
    },
    siteNonwinter: { type: "siteNonwinter", checked: false, sendIfNecessary: true },
    threeDaySummaryNonwinter: {
      type: "threeDaySummaryNonwinter",
      checked: false,
      sendIfNecessary: true
    },
    snowtify: {
      type: "snowtify",
      checked: false
    }
  },
  enabledReportAssignment: false,
};

const bulkSiteAssignment = (state = initialState, action) => {
  switch (action.type) {
    case BULK_STORE_SITETOUNASSIGN:
      return {
        ...state,
        siteToUnassign: action.id //[...new Set([...state.siteToUnassign, action.id])] // unique array
      };

    case BULK_STORE_SITEPERSONTOUNASSIGN:
      return immutable.set(
        state,
        [
          "sitePersonsToUnassign",
          action.siteId || state.siteToUnassign,
          action.id
        ],
        true
      );

    case BULK_UNDO_SITEPERSONUNASSIGN: {
      let newState = action.pid
        ? immutable.del(state, [
            "sitePersonsToUnassign",
            action.sid,
            action.pid
          ])
        : immutable.del(state, ["sitePersonsToUnassign", action.sid]);
      // delete site key if it points to empty map
      if (
        newState.sitePersonsToUnassign[action.sid] &&
        !Object.keys(newState.sitePersonsToUnassign[action.sid]).length
      )
        newState = immutable.del(state, ["sitePersonsToUnassign", action.sid]);
      return newState;
    }

    case BULK_ADD_USER:
      return {
        ...state,
        userIDs: [...new Set([...state.userIDs, action.id])] // unique array
      };

    case BULK_DEL_USER:
      return {
        ...state,
        userIDs: state.userIDs.filter(id => id !== action.id)
      };

    case BULK_RESET:
      return initialState;

    case BULK_SET_TABLE_SORT_KEY:
      return {
        ...state,
        tableSortKey: action.key,
        tableSortDirection:
          action.direction ||
          (action.key !== state.tableSortKey ? 1 : state.tableSortDirection * -1) // toggle direction if same key
      };

    case BULK_ENABLE_REPORT_ASSIGNMENT:
      return {
        ...state,
        enabledReportAssignment: action.enabled
      };

    case BULK_SET_REPORTTYPE:
      return immutable.set(
        state,
        ["reports", action.reportType, "checked"],
        action.checked
      );

    case BULK_SET_REPORT_SUBTYPE:
      return immutable.set(
        state,
        ["reports", action.reportType, "sendIfNecessary"],
        action.sendIfNecessary === true || action.sendIfNecessary === 'true'
      );

    default:
      return state;
  }
};

export default bulkSiteAssignment;
