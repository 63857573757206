import React from "react";
import PropTypes from "prop-types";

import {
  CITY_STATE,
  ZIP_CODE,
  ALL_FIELDS,
  NAME,
  STATE,
  ORDER_OPTIONS
} from "./SiteSelectorDuck";
import "./SiteSelector.css";
import ToggleButton from "common/components/ToggleButton";
import MultiSearchBoxSelector from "common/components/MultiSearchBoxSelector";
import SiteSelectorDropdown, {
  SiteSelectorOrderDropdown
} from "./SiteSelectorDropdown";
import { getSiteIcons } from "common/helpers/helpers";

// Control to select site or sites according to different filters (name, city, zipCode etc and searchbox).
// By default, the selected site's ID is stored in Redux through the props.addSite() function, but this can be overridden by providing a 'handleSelect' prop to SiteSelector.
// The sites considered to show must be a map 

class SiteSelector extends React.Component {
  handleInputChange = value => this.props.setInputValue(value);

  addSelectedID = id => {
    this.props.addSite(id);
  };

  iconFunc = id => getSiteIcons(this.props.sites[id]);

  render() {
    const {
      dataSources,
      inputValue,
      selectSiteBy,
      selectSiteOrderBy,
      setSiteSelectedBy,
      setSiteSelectedOrderBy,
      showMySitesToggle,
      hasMySites,
      handleSelect
    } = this.props;

    const handleSelectChange = e => {
      setSiteSelectedBy(e.target.value);
    };

    const handleSiteOrderChange = e => setSiteSelectedOrderBy(e.target.value);

    return (
      <div>
        {showMySitesToggle && (
          <div className='SiteSel__Toggle'>
            <div className="WA__Subhead">
              Filter from company sites or my sites
            </div>
            <ToggleButton
              buttonGroupId="watchListAddSiteFilter"
              leftLabel="Company Sites"
              rightLabel="My Sites"
              disabled={!hasMySites}
            />
          </div>
        )}

        <div className="SiteSel__Dropdowns">
          <SiteSelectorDropdown
            optionArray={[ALL_FIELDS, NAME, STATE, CITY_STATE, ZIP_CODE]}
            handleSelectChange={handleSelectChange}
            selectSiteBy={selectSiteBy}
          />

          <SiteSelectorOrderDropdown
            optionMap={ORDER_OPTIONS}
            handleSelectChange={handleSiteOrderChange}
            selectSiteOrderBy={selectSiteOrderBy}
          />
        </div>

        <MultiSearchBoxSelector
          placeHolder="Enter search term"
          dataSources={dataSources}
          fields={["name", "city", "state", "zipCode"]}
          handleSelect={handleSelect || this.addSelectedID}
          onChange={this.handleInputChange}
          minSearchLen={0}
          value={inputValue}
          iconFunc={this.iconFunc}
        />
      </div>
    );
  }
}

SiteSelector.propTypes = {
  selectSiteBy: PropTypes.string.isRequired,
  addSite: PropTypes.func.isRequired,
  delSite: PropTypes.func.isRequired,
  setSiteSelectedBy: PropTypes.func.isRequired,
  handleSelect: PropTypes.func
};

export default SiteSelector;
