import { connect } from "react-redux";
import {
  subscribeSiteReport,
  updateReport,
  unsubscribeSiteReport
} from "common/dataRetrieval/ReportsDuck";
import AssignmentPrint from "./AssignmentPrint";
import { matchSearchTerm, isThirdPartyUser } from "common/helpers/helpers";
import { createSelector } from "reselect";

const selectUserReportSubscription = state => state.userReportSubscriptions;
const selectAssignmentList = (state, ownProps) => ownProps.assignmentList;
const selectSearchbox = (state, ownProps) => state.searchbox['assignmentList'];

const filterBySearchbox = (a, searchbox) => {
  if (!searchbox || !searchbox.value) return true;
  const matches = matchSearchTerm(searchbox.value);
  return matches(a.name) || matches(a.user) || matches(a.email);
}

// augment ownProps.assignmentList with current values for report subscriptions
const getCsvAssignmentList = createSelector(
  selectUserReportSubscription,
  selectAssignmentList,
  selectSearchbox,
  (userReportSubscriptions, assignmentList, searchbox) =>
  assignmentList
  .filter(a => filterBySearchbox(a, searchbox))
  .map(a => {
      const report24 =
        (userReportSubscriptions[a.userId] &&
          userReportSubscriptions[a.userId].find(
            rs => rs.siteId === a.siteId && rs.type === "site"
          )) ||
        {};
      const report72 =
        (userReportSubscriptions[a.userId] &&
          userReportSubscriptions[a.userId].find(
            rs => rs.siteId === a.siteId && rs.type === "threeDaySummary"
          )) ||
        {};
      return {
        ...a,
        report24: report24.type
          ? report24.sendIfNecessary
            ? "Events Only"
            : "Always"
          : "",
        report72: report72.type
          ? report72.sendIfNecessary
            ? "Events Only"
            : "Always"
          : ""
      };
    })
);

const mapStateToProps = (state, ownProps) => ({
  userReportSubscriptions: state.userReportSubscriptions,
  csvAssignmentList: getCsvAssignmentList(state, ownProps),
  isFetching:
    state.userReportSubscriptions.isFetching ||
    state.userReportSubscriptions.isFetchingGet
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleReportCheckboxChange: (e, user, siteId, siteReportType) => {
    const userType = isThirdPartyUser(user) ? "third-party-user" : "person";
    if (e.target.checked)
      dispatch(
        subscribeSiteReport(user.id, user.companyId, siteId, siteReportType, userType)
      );
    else
      dispatch(
        unsubscribeSiteReport(user.id, user.companyId, siteId, siteReportType, userType)
      );
  },

  handleReportSubtypeChange: (e, user, subscription) => {
    const userType = isThirdPartyUser(user) ? "third-party-user" : "person";
    dispatch(
      updateReport(user.id, user.companyId, subscription.id, e.target.value, userType)
    );
  }
});

const AssignmentPrintContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AssignmentPrint);

export default AssignmentPrintContainer;
