import log from 'loglevel';
import { put, takeEvery, call, select } from 'redux-saga/effects';

import {
  INVITEPERSON_SUBMIT,
  INVITEPERSON_SUCCESS,
  INVITEPERSON_FAILURE,
  INVITEPERSON_CLOSE,
  INVITEAGAIN_SUBMIT,
  INVITEAGAIN_SUCCESS,
  INVITEAGAIN_FAILURE
} from './InvitePersonDuck';
import { COMPANY_PERSONDATA_SUBMIT } from 'common/dataRetrieval/CompanyDuck';
import { SET_PERSON_SITE } from 'common/dataRetrieval/PersonDuck';
import fetchApi from 'utils/fetchApi';

const randomPwd = () => {
  let length = 8,
    charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789',
    retVal = '';
  for (var i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
};

export function* fetchInvitePersonSaga(action) {
  try {
    // redux-form-submit-saga injects 'payload' into action
    const {
      username,
      email,
      password,
      companyId,
      roles,
      siteId,
      verificationOverride,
      newRole,
      title,
      officePhone,
      extension,
      mobilePhone,
      noSmsNotification
    } = action.payload;
    const url = '/Persons';
    const payload = {
      email: email.trim(),
      companyId,
      password: verificationOverride ? password.trim() : randomPwd(),
      username: (username && username.trim()) || email.trim(),
      roles: Array.from(new Set(newRole ? [newRole, ...roles] : roles)), // unique role
      verificationOverride,
      title: title ? title.trim() : undefined,
      officePhone: officePhone ? officePhone.trim() : undefined,
      extension: extension ? extension.trim() : undefined,
      mobilePhone: mobilePhone ? mobilePhone.trim() : undefined,
      noSmsNotification
    };
    const authToken = yield select(state => state.login.authToken);

    const parsedJson = yield call(fetchApi, url, { payload, authToken });

    if (!parsedJson.error) {
      yield put({ type: INVITEPERSON_SUCCESS, personObj: parsedJson });
      yield put({ type: INVITEPERSON_CLOSE });

      if (siteId)
        yield put({
          type: SET_PERSON_SITE,
          data: { siteId, personId: parsedJson.id, companyId }
        });
    } else {
      yield put({
        type: INVITEPERSON_FAILURE,
        payload: parsedJson.error.text._error
          ? { ...parsedJson.error.text }
          : { _error: parsedJson.error.text }
      });
    }
  } catch (error) {
    log.error('fetchInvitePersonSaga', error);
    yield put({
      type: INVITEPERSON_FAILURE,
      payload: 'Network error.'
    });
  }
}

export function* postInvitePersonAgainSaga(action) {
  try {
    // redux-form-submit-saga injects 'payload' into action
    const { id } = action;
    const url = `/Persons/${id}/verify`;
    const authToken = yield select(state => state.login.authToken);

    // call API
    const data = yield call(fetchApi, url, { authToken });

    if (!data.error) {
      yield put({ type: INVITEAGAIN_SUCCESS });
    } else {
      yield put({ type: INVITEAGAIN_FAILURE });
    }
  } catch (error) {
    log.error('postInvitePersonAgainSaga error', error);
    yield put({
      type: INVITEAGAIN_FAILURE,
      message: error.message,
      payload: 'Network error.'
    });
  }
}

function* fetchCompanyPersonsAfterInviteCall({ personObj }) {
  if (personObj.roles.includes('company-admin')) {
    yield put({
      type: COMPANY_PERSONDATA_SUBMIT,
      id: personObj.companyId
    });
  }
}

// listen for actions of type INVITEPERSON_SUBMIT and use them
export default function* invitePersonSaga() {
  yield takeEvery(INVITEPERSON_SUBMIT, fetchInvitePersonSaga);
  yield takeEvery(INVITEAGAIN_SUBMIT, postInvitePersonAgainSaga);
  yield takeEvery(INVITEPERSON_SUCCESS, fetchCompanyPersonsAfterInviteCall);
}
