import { TAB_ASSIGN } from 'Admin/BulkSiteAssignment/BulkSiteAssignment';
import { USERUNASSIGN_BULK_SUCCESS, USERUNASSIGN_BULK_FAILURE } from 'Admin/SiteUsersManage/UserUnassign/UserUnassignDuck';
import { SITESELECT_SET_INPUT_VALUE } from 'common/components/SiteSelector/SiteSelectorDuck';
export const USERASSIGN_OPEN = "USERASSIGN_OPEN";
export const USERASSIGN_CLOSE = "USERASSIGN_CLOSE";
export const USERASSIGN_SUBMIT = "USERASSIGN_SUBMIT";
export const USERASSIGN_SUCCESS = "USERASSIGN_SUCCESS";
export const USERASSIGN_FAILURE = "USERASSIGN_FAILURE";
export const USERASSIGN_BULK = "USERASSIGN_BULK";
export const USERASSIGN_BULK_SUCCESS = "USERASSIGN_BULK_SUCCESS";
export const USERASSIGN_BULK_FAILURE = "USERASSIGN_BULK_FAILURE";
export const USERASSIGN_BULK_RESET_STATUS = "USERASSIGN_BULK_RESET_STATUS";

export const USERASSIGN_SET_ACTIVE_TAB = "USERASSIGN_SET_ACTIVE_TAB";

export const resetBulkStatus = () => ({
  type: USERASSIGN_BULK_RESET_STATUS
});

export const closeAssignModal = () => ({
  type: USERASSIGN_CLOSE
});

export const openAssignModal = () => ({
  type: USERASSIGN_OPEN
});

export const assignUsersToSites = (
  siteArr,
  userArr,
  siteReportsToSubscribe
) => ({
  type: USERASSIGN_BULK,
  siteArr,
  userArr,
  siteReportsToSubscribe
});

export const setActiveTab = tabId => ({
  type: USERASSIGN_SET_ACTIVE_TAB,
  tabId
});

const initialState = {
  showModal: false,
  isFetching: false,
  errorMessage: undefined,
  tabId: TAB_ASSIGN,
  bulkAssignOpSuccess: undefined,
  isFetchingBulk: false
};

const userAssign = (state = initialState, action) => {
  switch (action.type) {
    case USERASSIGN_OPEN:
      return {
        ...state,
        showModal: true
      };
    case USERASSIGN_CLOSE:
      return initialState;

    case USERASSIGN_SUBMIT:
      return {
        ...state,
        isFetching: true,
        errorMessage: undefined
      };
    case USERASSIGN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        showModal: false
      };

    case USERASSIGN_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload
      };

    case USERASSIGN_SET_ACTIVE_TAB:
      return {
        ...state,
        tabId: action.tabId,
        bulkAssignOpSuccess: undefined
      };

    case USERASSIGN_BULK:
      return {
        ...state,
        bulkAssignOpSuccess: undefined,
        isFetchingBulk: true,
        errorMessage: ""
      };

    case USERASSIGN_BULK_SUCCESS:
      return {
        ...state,
        isFetchingBulk: false,
        bulkAssignOpSuccess: true
      }

    case USERASSIGN_BULK_FAILURE:
      return {
        ...state,
        bulkAssignOpSuccess: false,
        isFetchingBulk: false,
        errorMessage: action.payload
      }

    case USERUNASSIGN_BULK_SUCCESS:
      return {
        ...state,
        bulkAssignOpSuccess: true
      }

    case USERUNASSIGN_BULK_FAILURE:
      return {
        ...state,
        bulkAssignOpSuccess: false
      }

    case SITESELECT_SET_INPUT_VALUE:
      return {
        ...state,
        bulkAssignOpSuccess: undefined
      }

    case USERASSIGN_BULK_RESET_STATUS:
      return { 
        ...state, 
        bulkAssignOpSuccess: undefined 
      };

    default:
      return state;
  }
};

export default userAssign;
