import log from 'loglevel';
import { put, takeEvery, call } from 'redux-saga/effects';

import { UPDATE_WINTER_TOGGLE } from 'common/dataRetrieval/CompanyDuck';
import {
  LOGIN_SUBMIT,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  USERDATA_SUCCESS,
} from './LoginDuck';
import fetchApi from 'utils/fetchApi';

export function* fetchLoginSaga(action) {
  try {
    // redux-form-submit-saga injects 'payload' into action
    const { username, password } = action.payload;
    const url = '/persons/login';
    const payload = { email: username?.trim(), password: password?.trim() };

    // call API
    const parsedJson = yield call(fetchApi, url, { payload });

    const authToken = parsedJson.id;

    if (!parsedJson.error) {
      const parsedUserJson = yield call(
        fetchApi,
        `/persons/${parsedJson.userId}`,
        { method: 'GET', authToken }
      );
  
      yield put({ type: USERDATA_SUCCESS, userObj: parsedUserJson });
      yield put({ type: LOGIN_SUCCESS, userObj: parsedJson });
      yield put({ type: UPDATE_WINTER_TOGGLE, companyId: parsedJson.companyId });
    } else {
      // 'payload' is the redux-form-submit-saga object that is given wholesale to
      // react-final-form's error state
      yield put({
        type: LOGIN_FAILURE,
        message: parsedJson.error.text,
        payload: parsedJson.error.text._error ? {...parsedJson.error.text} : {_error: parsedJson.error.text},
      });
    }
  } catch (error) {
    log.error('fetchLoginSaga error', error); // something happened during the network call
    yield put({
      type: LOGIN_FAILURE,
      message: 'Failed to login',
      payload: {_error: 'Network error.'}
    });
  }
}

// listen for actions of type LOGIN_SUBMIT and use them
export default function* loginSaga() {
  yield takeEvery(LOGIN_SUBMIT, fetchLoginSaga);
}
