import { COMPANYADD_SUCCESS, } from 'Admin/CompanyAdd/CompanyAddDuck'
import { COMPANYEDIT_SUCCESS } from 'Admin/CompanyEdit/CompanyEditDuck'
import { PERSONDEL_SUCCESS } from 'common/dataRetrieval/PersonDuck'
import { arr2obj } from 'common/helpers/datahelpers'
import { COMPANYDELETE_SUCCESS } from 'Admin/CompanyEdit/CompanyEditDuck';

export const COMPANYDATA_SUBMIT = 'COMPANYDATA_SUBMIT'
export const COMPANYDATA_SUCCESS = 'COMPANYDATA_SUCCESS'
export const COMPANYDATA_FAILURE = 'COMPANYDATA_FAILURE'
export const COMPANY_PERSONDATA_SUCCESS = 'COMPANY_PERSONDATA_SUCCESS'
export const COMPANY_PERSONDATA_SUBMIT = 'COMPANY_PERSONDATA_SUBMIT'
export const ALL_PERSONDATA_SUCCESS = 'ALL_PERSONDATA_SUCCESS'

export const COMPANY_APISUBSCRIPTIONS_SUBMIT = 'COMPANY_APISUBSCRIPTIONS_SUBMIT';
export const COMPANY_APISUBSCRIPTIONS_SUCCESS = 'COMPANY_APISUBSCRIPTIONS_SUCCESS';
export const COMPANY_APISUBSCRIPTIONS_FAILURE = 'COMPANY_APISUBSCRIPTIONS_FAILURE';
export const COMPANY_APISUBSCRIPTIONS_DELETED = 'COMPANY_APISUBSCRIPTIONS_DELETED';
export const COMPANY_APISUBSCRIPTIONS_SET_SUBMIT = 'COMPANY_APISUBSCRIPTIONS_SET_SUBMIT';
export const COMPANY_APISUBSCRIPTIONS_SET_SUCCESS = 'COMPANY_APISUBSCRIPTIONS_SET_SUCCESS';
export const COMPANY_APISUBSCRIPTIONS_SET_FAILURE = 'COMPANY_APISUBSCRIPTIONS_SET_FAILURE';

export const UPDATE_WINTER_TOGGLE = 'UPDATE_WINTER_TOGGLE';

export const loadCompanies = (companyId, fullFetch) => {
  return {
    type: COMPANYDATA_SUBMIT,
    companyId,
    fullFetch
  }
}

export const loadCompanyPersons = (companyId, withReportSubscriptions) => ({
  type: COMPANY_PERSONDATA_SUBMIT,
  id: companyId,
  withReportSubscriptions
})

export const loadCompanyApiSubscription = companyId => ({
  type: COMPANY_APISUBSCRIPTIONS_SUBMIT,
  companyId
})

export const updateWinterToggle = companyId => ({
  type: UPDATE_WINTER_TOGGLE,
  companyId
});

const initialState = {
}

const companies = (state = initialState, action) => {
    switch(action.type){
      case COMPANYDATA_SUCCESS:
        return arr2obj(action.payload);

      case COMPANYADD_SUCCESS:
        return {
          ...state,
          [action.companyObj.id]: action.companyObj
        }

      case COMPANYEDIT_SUCCESS:
        return {
          ...state,
          [action.companyObj.id]: action.companyObj
        }

      case COMPANYDELETE_SUCCESS:
        let {[action.companyId]: deleted, ...newState} = state;
        return newState

      case COMPANY_APISUBSCRIPTIONS_SUCCESS:
        if (action.data)
          return {
            ...state,
            [action.data.companyId]: {
              ...state[action.data.companyId], 
              apiSubscription: action.data, 
              apiEnabled: action.data && !!action.data.apiPackageId 
            }
          }
        else
          return {
            ...state,
            [action.companyId]: {
              ...state[action.companyId], 
              apiEnabled: false
            }
          }

      case COMPANY_APISUBSCRIPTIONS_DELETED:
        return {
          ...state,
          [action.companyId]: {
            ...state[action.companyId], 
            apiSubscription: undefined,
            apiEnabled: false
          }
        }

      case COMPANY_APISUBSCRIPTIONS_FAILURE:
        return {
          ...state,
          [action.companyId]: {...state[action.companyId], apiSubscription: {}}
        }

      default :
        return state;
    }
}

const deletePersonFromCompany = (state, personId) => {
  let newState = {}
  Object.keys(state).forEach(c => newState[c] = state[c].filter(p => p.id !== personId))
  return newState
}

const mapCompanyPersons = ({personArr}) => {
  const newState = {};
  personArr.forEach(u => (newState[u.companyId] || (newState[u.companyId] = [])).push(u));
  return newState;
};

export const companyPersons = (state = initialState, action) => {
    switch(action.type){
      case COMPANY_PERSONDATA_SUCCESS:
        return {
          ...state,
          [action.companyId]: action.personArr
        }

      case PERSONDEL_SUCCESS:
        return deletePersonFromCompany(state, action.id)

      case ALL_PERSONDATA_SUCCESS:
        return {
          ...mapCompanyPersons(action)
        }

      default :
        return state;
    }
}


export default companies
