import React from "react";
import PropTypes from "prop-types";
import { Table, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import sortArrow from "images/dashboard/dropdown-arrow.svg";
import SitesDownload from "common/components/SitesDownload";
import chevronRight from "images/dashboard/chevron-right.svg";
import SiteReportCheckbox from "Admin/SiteUsersManage/SiteReportCheckbox";
import BusySpinner from "common/components/BusySpinner";
import SearchBox from "common/components/SearchBox";
import "./AssignmentPrint.css";
import "Admin/common/css/Table.css";

const AssignmentList = ({
  csvAssignmentList,
  setTableSortKey,
  companyId,
  handleReportCheckboxChange,
  handleReportSubtypeChange,
  userReportSubscriptions,
  sites,
  isFetching,
  companySubscription
}) => (
  <div className="AssignmentList__outer">
    <div style={{marginBottom: '8px'}}>
    <span style={{ minHeight: "26px", color: "#7F8FA4" }}>
      Download as CSV &nbsp;
      <SitesDownload
        tooltip="Download All Assignments as CSV"
        data={csvAssignmentList}
        fileName={`assignments.csv`}
        keys={[
          "name",
          "city",
          "state",
          "zipCode",
          "user",
          "role",
          "report24",
          "report72"
        ]}
        headers={[
          "Name",
          "City",
          "State",
          "Zip Code",
          "User",
          "Role",
          "24h Report",
          "72h Report"
        ]}
      />
      {isFetching && (
        <BusySpinner
          style={{
            fontSize: "14px",
            marginLeft: "8px",
            marginTop: "-6px",
            color: "#4758BE"
          }}
        />
      )}
    </span>{" "}

    <span style={{marginLeft: '16px'}}>
      <SearchBox
        boxId="assignmentList"
        placeHolder="Enter site name or username"
        width={300}
      />
    </span>
  </div>

    <Table striped bordered condensed="true" responsive>
      <thead>
        <tr className="WWA__Table--Header">
          <th
            style={{ cursor: "pointer" }}
            onClick={e => setTableSortKey(e.target.id)}
            id="name"
          >
            Site Name &nbsp; <img src={sortArrow} alt={"sort by name"} />
          </th>
          <th
            style={{ cursor: "pointer" }}
            onClick={e => setTableSortKey(e.target.id)}
            id="city"
          >
            City &nbsp; <img src={sortArrow} alt={"sort by name"} />
          </th>
          <th
            style={{ cursor: "pointer" }}
            onClick={e => setTableSortKey(e.target.id)}
            id="state"
          >
            State &nbsp; <img src={sortArrow} alt={"sort by state"} />
          </th>
          <th
            style={{ cursor: "pointer" }}
            onClick={e => setTableSortKey(e.target.id)}
            id="zipCode"
          >
            Zip Code &nbsp; <img src={sortArrow} alt={"sort by name"} />
          </th>
          <th
            style={{ cursor: "pointer" }}
            onClick={e => setTableSortKey(e.target.id)}
            id="user"
          >
            User &nbsp; <img src={sortArrow} alt={"sort by name"} />
          </th>

          <th
            style={{ cursor: "pointer" }}
            onClick={e => setTableSortKey(e.target.id)}
            id="role"
          >
            Role &nbsp; <img src={sortArrow} alt={"sort by name"} />
          </th>

          <th>24h Report</th>
          <th>72h Report</th>
          {companySubscription.callToAction &&
            <th>Snowtify</th>
          }

          <th />
        </tr>
      </thead>
      <tbody>
        {csvAssignmentList.length ? (
          csvAssignmentList.map(a => (
            <tr key={`${a.siteId}-${a.userId}`}>
              <td>{a.name}</td>
              <td>{a.city}</td>
              <td>{a.state}</td>
              <td>{a.zipCode}</td>
              <td>
                {a.user} {a.email !== a.user ? "<" + a.email + ">" : ""}
              </td>
              <td>{a.role}</td>

              <td>
                {sites[a.siteId].types.includes('winter') &&
                  <>
                    <SiteReportCheckbox
                      u={a.userObj}
                      reportType={"site"}
                      winterType={"winter"}
                      siteId={a.siteId}
                      handleReportCheckboxChange={handleReportCheckboxChange}
                      handleReportSubtypeChange={handleReportSubtypeChange}
                      reportSubs={
                        userReportSubscriptions[a.userObj.id]
                          ? userReportSubscriptions[a.userObj.id].find(
                              rs => rs.siteId === a.siteId && rs.type === "site"
                            )
                          : undefined
                      }
                      isFetching={isFetching}
                    />
                    <br/>
                  </>
                }
                {sites[a.siteId].types.includes('nonwinter') &&
                  <>
                    <SiteReportCheckbox
                      u={a.userObj}
                      reportType={"siteNonwinter"}
                      winterType={"nonwinter"}
                      siteId={a.siteId}
                      handleReportCheckboxChange={handleReportCheckboxChange}
                      handleReportSubtypeChange={handleReportSubtypeChange}
                      reportSubs={
                        userReportSubscriptions[a.userObj.id]
                          ? userReportSubscriptions[a.userObj.id].find(
                              rs => rs.siteId === a.siteId && rs.type === "siteNonwinter"
                            )
                          : undefined
                      }
                      isFetching={isFetching}
                    />
                  </>
                }
              </td>
              <td>
                {sites[a.siteId].types.includes('winter') &&
                  <>
                    <SiteReportCheckbox
                      u={a.userObj}
                      reportType={"threeDaySummary"}
                      winterType={"winter"}
                      siteId={a.siteId}
                      handleReportCheckboxChange={handleReportCheckboxChange}
                      handleReportSubtypeChange={handleReportSubtypeChange}
                      reportSubs={
                        userReportSubscriptions[a.userObj.id]
                          ? userReportSubscriptions[a.userObj.id].find(
                              rs =>
                                rs.siteId === a.siteId &&
                                rs.type === "threeDaySummary"
                            )
                          : undefined
                      }
                      isFetching={isFetching}
                    />
                    <br/>
                  </>
                }
                {sites[a.siteId].types.includes('nonwinter') &&
                  <>
                    <SiteReportCheckbox
                      u={a.userObj}
                      reportType={"threeDaySummaryNonwinter"}
                      winterType={"nonwinter"}
                      siteId={a.siteId}
                      handleReportCheckboxChange={handleReportCheckboxChange}
                      handleReportSubtypeChange={handleReportSubtypeChange}
                      reportSubs={
                        userReportSubscriptions[a.userObj.id]
                          ? userReportSubscriptions[a.userObj.id].find(
                              rs =>
                                rs.siteId === a.siteId &&
                                rs.type === "threeDaySummaryNonwinter"
                            )
                          : undefined
                      }
                      isFetching={isFetching}
                    />
                  </>
                }
              </td>

              {companySubscription.callToAction &&
                <td>
                  <SiteReportCheckbox
                    u={a.userObj}
                    reportType={"snowtify"}
                    winterType={"snowtify"}
                    siteId={a.siteId}
                    handleReportCheckboxChange={handleReportCheckboxChange}
                    reportSubs={
                      userReportSubscriptions[a.userObj.id]
                        ? userReportSubscriptions[a.userObj.id].find(
                          rs =>
                          rs.siteId === a.siteId &&
                          rs.type === "snowtify"
                        )
                        : undefined
                    }
                    isFetching={isFetching}
                  />
                </td>
              }

              <td>
                <div className="AssignmentList_GreaterThan">
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id={"powerhintOff"}>
                        Go to site/user reports
                      </Tooltip>
                    }
                  >
                    <Link
                      to={`/admin-panel/companies/${companyId}/sites/${
                        a.siteId
                      }/company-users`}
                    >
                      <img src={chevronRight} alt="Go to site" />
                    </Link>
                  </OverlayTrigger>
                </div>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="6" className="AssignmentList__nodata">
              No data to show.
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  </div>
);

AssignmentList.propTypes = {
  csvAssignmentList: PropTypes.arrayOf(PropTypes.object),
  companyPersons: PropTypes.arrayOf(PropTypes.object)
};

export default AssignmentList;
