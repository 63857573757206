import log from "loglevel";
import { put, take, takeEvery, spawn, call, select } from "redux-saga/effects";
import { isThirdPartyUser } from "common/helpers/helpers";
import { runSemaphore } from "common/helpers/semaphore";
import { SITESELECT_RESET } from "common/components/SiteSelector/SiteSelectorDuck";
import { BULK_RESET } from "Admin/BulkSiteAssignment/BulkSiteAssignmentDuck";
import { USER_REPORTSUBS_SUBMIT } from "common/dataRetrieval/ReportsDuck";

import {
  USERASSIGN_SUBMIT,
  USERASSIGN_SUCCESS,
  USERASSIGN_FAILURE,
  USERASSIGN_BULK,
  USERASSIGN_BULK_SUCCESS,
  USERASSIGN_BULK_FAILURE
} from "./UserAssignDuck";
import fetchApi from "utils/fetchApi";
import { SUBSCRIBE_REPORT_SUBMIT } from "../../../common/dataRetrieval/ReportsDuck";
import { reportTypes } from "common/helpers/helpers";

const USER_REPORTS_SEMAPHORE_INC = 'USER_REPORTS_SEMAPHORE_INC';
const USER_REPORTS_SEMAPHORE_DEC = 'USER_REPORTS_SEMAPHORE_DEC';
const SEMAPHORE_NAME = 'BULKASSIGN_USER_REPORTSUBSCRIPTIONS';

// assigns one person to one site and optionally subscribes to reports
function* fetchUserAssignSaga(action) {
  const { person, site, siteId, companyId, siteReportsToSubscribe = [] } = action;
  const personId = person.id;

  const url = !isThirdPartyUser(person)
    ? `/Persons/${personId}/Sites/rel/${siteId}`
    : `/ThirdPartyUsers/${personId}/Sites/rel/${siteId}`;

  const authToken = yield select(state => state.login.authToken);
  const payload = { companyId };

  const parsedJson = yield call(fetchApi, url, {
    method: "PUT",
    payload,
    authToken
  });

  if (!parsedJson.error) {
    if(siteReportsToSubscribe){
      for (let sr of siteReportsToSubscribe) {
        // don't subscribe nonwinter only site to winter type reports and vice versa
        if((site.types.includes('winter') && reportTypes['winter'].includes(sr.type)) ||
          (site.types.includes('nonwinter') && reportTypes['nonwinter'].includes(sr.type)) ||
          sr.type === "snowtify") {

          yield put({
            type: SUBSCRIBE_REPORT_SUBMIT,
            userId: personId,
            siteId,
            companyId,
            siteReportType: sr.type,
            sendIfNecessary: sr.sendIfNecessary
          });
        } else {
          log.debug(
            `Not subscribing to incompatible report '${sr.type}' for site ${JSON.stringify(site)}`
          );
        }
      }
    }

    yield put({ type: USERASSIGN_SUCCESS, personObj: parsedJson });
    yield put({ type: "@@redux-form/RESET", meta: { form: "UserAssignForm" } });
  } else {
    yield put({
      type: USERASSIGN_FAILURE,
      payload: parsedJson.error.text
    });
    return parsedJson.error.text;
  }
}

function* putAllUserAssignSaga(action) {
  try {
    for (let p of action.payload.persons) {
      yield call(fetchUserAssignSaga, { person: p, ...action.payload });
    }
  } catch (error) {
    yield put({
      type: USERASSIGN_FAILURE,
      payload: 'Error assigning User'
    });
    log.error("fetchUserAssignSaga", error);
  }
}

function* putAllSitesAndUsersAssignSaga(action) {
  try {
    const { siteArr, userArr, siteReportsToSubscribe } = action;

    let err;
    for (let site of siteArr) {
      for (let user of userArr) {
        err = yield call(fetchUserAssignSaga, {
          person: user,
          site,
          siteId: site.id,
          companyId: user.companyId,
          siteReportsToSubscribe
        });
        if (err) break;
      }
    }

    // fetch report subscriptions for all users
    if (siteReportsToSubscribe && siteReportsToSubscribe.length > 0) {
      for (let user of userArr) {
        yield put({ type: USER_REPORTS_SEMAPHORE_INC });
        yield put({
          type: USER_REPORTSUBS_SUBMIT,
          userId: user.id,
          userType: isThirdPartyUser(user) ? "third-party-user" : "person",
          // will be put when USER_REPORTSUBS_SUBMIT finished:
          cbAction: USER_REPORTS_SEMAPHORE_DEC
        });
      }

      // wait for all user report subscriptions fetches finished
      yield take(`${SEMAPHORE_NAME}_SEMAPHORE_ZERO`);
    }

    if (!err) {
      yield put({ type: USERASSIGN_BULK_SUCCESS });
      yield put({ type: BULK_RESET });
      yield put({ type: SITESELECT_RESET });
    } else
      yield put({
        type: USERASSIGN_BULK_FAILURE,
        payload: err
      });
  } catch (error) {
    log.error("fetchBulkUserAssignSaga", error);
    yield put({
      type: USERASSIGN_BULK_FAILURE,
      message: error
    });
  }
}

// listen for actions of type UserAssign_SUBMIT and use them
export default function* userAssignSaga() {
  yield spawn(
    runSemaphore,
    SEMAPHORE_NAME,
    [USER_REPORTS_SEMAPHORE_INC],
    [USER_REPORTS_SEMAPHORE_DEC]
  );
  // yield spawn (countCalls);
  yield takeEvery(USERASSIGN_SUBMIT, putAllUserAssignSaga);
  yield takeEvery(USERASSIGN_BULK, putAllSitesAndUsersAssignSaga);
}
