import log from 'loglevel';
import queryString from 'query-string';

/*
 * API query helpers; improved version on ajax.js to only require 1 call to fetch
 * which returns either the data or error (converted to human readable from loopback errors)
 */

/*
 * Caller to backend API; returns a promise
 *
 * The named parameters can be used like this:
 *  fetchApi(url, { authToken })
 *  fetchApi(url, { query:{access_token:authToken} })
 *  fetchApi(url, { payload: {username:"jon", password:"doe"}, 
 *                  query:{access_token:authToken} })
 *
 * @param {string} url The full URL to call. Obligatory.
 * @param {object} Named parameters that are deconstructed:
 * @param {string} prefix A prefix to use instead of "/api"; optional.
 * @param {string} payload The POST payload as a JSON object; optional.
 * @param {string} authToken The access token.
 * @param {object} query Object of keys and values that gets encoded for URI query. 
 * @param {object} method POST, GET etc; defaults to POST
 * @promise
 */
export default (
  url,
  { prefix = process.env.REACT_APP_API_URL ?? '/v2/fms', payload, authToken = '', query, method = 'POST' } = {}
) => {
  // construct query parameters:
  const queryStr = query ? `?${serializeQuery(query)}` : '';
  const fullquery = `${prefix}${url}${queryStr}`;

  // see if there's any body payload
  const body = payload ? JSON.stringify(payload) : undefined;

  // add headers
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  authToken && headers.append('X-Access-Token', authToken);

  const obj = {
    headers,
    accept: 'application/json',
    method,
    body
  };

  return fetch(fullquery, obj).then(response => handleResult(response));
};

/* convert query into URL query ?key=falue&key=value&... (encoded)
 * e.g. query={access_token: "kasdkfd"} -> ?access_token=kasdkfd
 */
const serializeQuery = obj => queryString.stringify(obj)

/*
 * Checks the return code of fetch call (in range 200-299)
 * @param {promise} response Fetch's respone.
 * @promise
 */
const checkStatus = response => {
  if (response.ok) {
    return response;
  }
  // log.warn('checkStatus', response.status);
  const error = new Error(`HTTP Error ${response.statusText}`);
  error.text = response.statusText;
  error.response = response;
  error.statusCode = error.response.status;
  throw error;
};

/*
 * Turn backend API errors from loopback into human readable messages.
 * This is where localization could be applied as well.
 */
const errorConverter = (err) => {
  switch (err.statusCode) {
    case 500:
      if (err.message === "User limit exceeded"){
        return {_error: err.message }
      } else if (err.message === 'Incorrect Site Data') {
        return {_error: err.data }
      } else if (err.message === 'already inactive') {
        return {_error: 'Site is already inactive' }
      } else if (err.message === 'no Subscription') {
        return {_error: err.message }
      } else if (err.message === 'File cannot be parsed') {
        return {_error: err.message }
      } else if (err.message === 'No file') {
        return {_error: 'The file does not exist' }
      } else if (err.message === 'Duplicate Site') {
        return {_error: 'This is a duplicate of an existing site' }
      } else if (err.message === 'Timezone Request Error') {
        if (err.details && err.details.trim() === 'ZERO_RESULTS')
          return {_error: 'Timezone matching found no results' }
        else return {_error: 'Timezone matching error' }
      } else if (err.message === 'Geocoding Request Error') {
        if (err.details && err.details.trim() === 'ZERO_RESULTS')
          return {_error: 'Geocoding found no results' }
        else return {_error: 'Geocoding error' }
      } else if (err.message === 'partial') {
        if (err.details && err.details.foundAddress)
          return {_error: 'Geocoding found partial match, please check the address: ' + err.details.foundAddress }
        else return {_error: 'Partial Match' }
      } else if (err.message.startsWith('Unsuitable Types.')) {
        return {_error: err.message}
      } else if (err.message === 'File cannot be parsed') {
        return {_error: err.message }
      }
      return {
        _error: 'An error occurred internally.'
      }

    case 503:
      if (err.code) {
        switch (err.code) {
          case 'MAINTENANCE_MODE':
            return {
              _error: 'System maintenance, please try again later.'
            }
          case 'OPERATIONS_IN_PROGRESS':
            return {
              _error: err.message
            }
          default: return 'An error occurred.'
        }
      } 
      return {
        _error: 'Server unavailable.'
      }

    case 400:
      if (err.message === 'Invalid Token') {
        return {error: 'Invalid Token. Verify you clicked the "Go To Downloads" link in the latest email. ' +
          'Old email links are invalidated.'} // reponse for /Reports/summary?token=<token>
      }
      if (err.code) {
        switch (err.code) {
          case 'EMAIL_REQUIRED':
            return {
              email: 'Email is required.'
            }
          case 'INVALID_TOKEN':
            return {
              error: 'Invalid token.',
              _error: 'Invalid token.'
            }
          case 'USERNAME_EMAIL_REQUIRED':
            return {
              username: 'Username or email is required.',
              _error: 'Login failed!'
            }
          default: return 'An error occurred.'
        }
      }
      break;

    case 401:
      if (err.code) {
        switch (err.code) {
          case 'LOGIN_FAILED':
            return {
              username: 'This combination of email and password is invalid.',
              _error: 'Login failed!'
            }
          case 'INVALID_TOKEN':
            return {
              _error: 'Invalid access token.'
            }
          case 'AUTHORIZATION_REQUIRED':
            return {
              _error: 'Authorization required.'
            }
          case 'RESET_FAILED_EMAIL_NOT_VERIFIED':
            return {
              _error: 'Please verify your email before resetting the password.'
            }
          default: return 'An error occurred.'
        }
      } else if (err.message === "No External Api Subscription")
        return 'Company has no API Subscription'
      break;

    case 403:
      if (err.code) {
        switch (err.code) {
          case 'EMAIL_NOT_VERIFIED':
            return {
              _error: 'Please verify your email before logging in.'
            }
          case 'COMPANY_NOT_ACTIVE':
            return {
              _error: 'Company is deactivated.'
            }
          case 'COMPANY_ALREADY_ACTIVE':
            return {
              isCompanyAlreadyActive: true,
              _error: err.message
            }
          case 'API_KEY_NOT_OWN_APP':
            return {
              _error: err.message
            }
          default: return 'An error occurred.'
        }
      } else if (err.message === "No External Api Subscription")
        return 'Company has no API Subscription'
      break;

    case 404:
      if (err.code) {
        switch (err.code) {
          case 'EMAIL_NOT_FOUND':
            return {
              email: 'Email not found.'
            }
          case 'USER_NOT_FOUND':
            return { _error: 'User not found.' }
          case 'MODEL_NOT_FOUND':
            return { _error: 'Nothing found'}
          case 'NO_SUCH_FREEZE_TIME':
            return { _error: err.message }
          case 'NO_SUBSCRIPTION_FOR_COMPANY':
            return { _error: 'Company not known.' }

          default: return 'An error occurred.'
        }
      }
      return 'Not found.';

    case 409:
      if (err.code) {
        switch (err.code) {
          case 'TRIAL_EMAIL_EXISTS':
          case 'TRIAL_COMPANY_EXISTS':
          case 'FREEZE_DATE_CONFLICT':
            return {
              _error: err.message
            }
          default: return 'An error occurred.'
        }
      }
      return 'Conflict';

    case 413:
      return {
        _error: 'Payload too large.'
      }

    case 422:
      if (err.code) {
        switch (err.code) {
          case 'INVALID_PASSWORD':
            return {
              password: 'Password must not be empty.',
              _error: 'Login failed!'
            }

          case 'PASSWORD_TOO_LONG':
            return {
              password: 'Password must not be longer than 72 characters.',
              _error: 'Login failed!'
            }
          default: return 'An error occurred.'
        }
      }
      if (err.details && err.details.codes) {
        if (err.details.codes.email) {
          switch (err.details.codes.email[0]) {
            case 'presence':
              return {
                email: 'Email must not be empty.',
                _error: 'Login failed!'
              }
            case 'uniqueness':
              return {
                email: 'Email is already taken.',
                _error: 'Email is already taken.'
              }
            case 'custom.email':
              return {
                email: 'Email is invalid.',
                _error: 'Login failed!'
              }
            default: return 'Validation failed for email.'
          }
        }
        if (err.details.codes.name) {
          switch (err.details.codes.name[0]) {
            case 'presence':
              return {
                name: 'Field must not be empty.',
              }
            default: return 'Validation failed for name.'
          }
        }
        if (err.details.messages.ipList) {
          return {
            ipList: err.details.messages.ipList[0]
          }
        }
      }
      return 'An error occurred (422).'

    default:
      return 'An error occurred.'
  }

  return 'An error occurred...'
}

const isJsonResponse = response => {
  const contentType = response.headers.get('content-type');
  return (
    contentType &&
    contentType.indexOf('application/json') !== -1 &&
    response.status !== 201 &&
    response.status !== 202 &&
    response.status !== 204
  );
};

/*
 * Unwraps return promise from fetch into JSON object
 * @param {promise} response Fetch's respone.
 * @return {object} JSON response data.
 */
const parseJSON = response => {
  if (isJsonResponse(response)) {
    return response
      .json()
      .then(function(json) {
        // log.debug(`got JSON: ${JSON.stringify(json)}`);
        return json;
      })
      .catch(e => {
        log.warn('Caught error in JSON promise:', e);
        const error = new Error('Server did not return JSON data.');
        throw error;
      });
  } else {
    log.warn("parseJSON(): We haven't got JSON!");
    const error = new Error('Server did not return JSON data.');
    throw error;
  }
};

function handleResult(response) {
  try {
    checkStatus(response);
    if (isJsonResponse(response)) {
      return parseJSON(response);
    } else {
      return response.blob();
    }
  } catch (error) {
    // log.warn('handleResponse caught', error);
    if (isJsonResponse(response)) {
      return parseJSON(response).then(json => ({
        error: {
          text: errorConverter(json.error)
        },
        notfound: response.status === 404
      }));
    } else {
      return {
        error: {
          text: error.text
        },
        notfound: response.status === 404
      };
    }
  }
}

// for file upload, the request must be different: no json content-type
export const postFormData = (
  url, 
  { body, authToken='', query, method='POST' } = {}
) => {
  // construct query parameters:
  const apiUrl = process.env.REACT_APP_API_URL ?? '';
  const queryStr = query ? `?${serializeQuery(query)}` : ''
  const fullquery = `${apiUrl}/v2/fms${url}${queryStr}`;

  // add headers
  const headers = new Headers()
  authToken && headers.append('X-Access-Token', authToken)

  const obj = {
    headers,
    accept: 'application/json',
    method,
    body
  }

  return fetch(fullquery, obj).then(response => handleResult(response));
}

// for file fetch
export const getBlob = (
  url, 
  { prefix = process.env.REACT_APP_API_URL ?? '/v2/fms', body, authToken='', query, method='GET' } = {}
) => {
  // construct query parameters:
  const queryStr = query ? `?${serializeQuery(query)}` : ''
  const fullquery = `${prefix}${url}${queryStr}`;

  // add headers
  const headers = new Headers()
  authToken && headers.append('X-Access-Token', authToken)

  const obj = {
    headers,
    accept: 'application/zip',
    method,
    body
  }

  return fetch(fullquery, obj).then(response => handleResult(response));
}
