import React from "react";
import { Alert, Button, Tabs, Tab } from "react-bootstrap";
import immutable from "object-path-immutable";
import BusySpinner from "common/components/BusySpinner";
import "./BulkSiteAssignment.css";
import MultiSearchBoxSelector from "common/components/MultiSearchBoxSelector/MultiSearchBoxSelector";
import ResultPool from "common/components/ResultWithDelete/ResultPool";
import SiteSelector from "common/components/SiteSelector";
import BulkSiteReportCheckbox from "./BulkSiteReportCheckbox";
import BulkSiteUnassignBySiteTab from "./BulkSiteUnassignBySiteTab";
import BulkSiteUnassignByUserTab from "./BulkSiteUnassignByUserTab";
import AssignmentPrint from "./AssignmentPrintContainer";
import { WwaCheckbox } from "../../common/components/WwaInputElements";
import "common/css/inputElements.css";
import { getUserIcons, getSiteIcons } from "common/helpers/helpers";

export const TAB_ASSIGN = "assign";
export const TAB_UNASSIGN_BY_SITE = "unassign-by-site";
export const TAB_UNASSIGN_BY_USER = "unassign-by-user";
export const TAB_ASSIGNMENTLIST = "assignmentlist";

class BulkSiteAssignment extends React.Component {
  state = {
      userSearchValue: "",
      selSite: {},
      selPers: {}
    };

  componentWillUnmount() {
    this.props.reset();
  }

  handleSelectUser = id => this.props.addUser(id);
  handleChangeUser = v => this.setState({ userSearchValue: v });

  handleCancel = e => {
    this.props.reset();
    this.props.history.push(
      `/admin-panel/companies/${this.props.companyId}/sites`
    );
  };

  handleSubmit = () => {
    const sites = this.props.sitesToAssign;
    const users = this.props.usersToAssign;
    const siteReportsToSubscribe = Object.values(this.props.reports).filter(
      r => r.checked
    );
    this.props.assignUsersToSites(sites, users, siteReportsToSubscribe);
  };

  handleSubmitUnassign = () => {
    const {
      companyId,
      sitePersonsToUnassignObjects,
      unassignUsers
    } = this.props;
    Object.keys(sitePersonsToUnassignObjects).forEach(siteId => {
      const userArr = sitePersonsToUnassignObjects[siteId];
      unassignUsers(companyId, siteId, userArr);
    });
  };

  handleReportCheckboxChange = e => {
    const type = e.target.id;
    const checked = e.target.checked;
    this.props.setReportsToAssign(type, checked);
  };

  handleReportSubtypeChange = e => {
    const type = e.currentTarget.id;
    const sendIfNecessary = e.currentTarget.value;
    this.props.setReportSubtypeChange(type, sendIfNecessary);
  };

  handleEnableReportAssignment = e => {
    const oldVal = this.props.enabledReportAssignment;
    this.props.enableReportAssignment(!oldVal);
    if (oldVal){
      this.props.setReportsToAssign('site', false);
      this.props.setReportsToAssign('threeDaySummary', false);
      this.props.setReportSubtypeChange('site', false);
      this.props.setReportSubtypeChange('threeDaySummary', false);
    }
  };

  handleSiteCb = ({ target: { id: sid } }) => {
    const nstate = this.state.selSite[sid]
      ? immutable.del(this.state, ["selSite", sid])
      : immutable.set(this.state, ["selSite", sid], true);
    this.setState({ selSite: nstate.selSite });
  };

  handlePersCb = ({ target: { id: pid } }) => {
    const nstate = this.state.selPers[pid]
      ? immutable.del(this.state, ["selPers", pid])
      : immutable.set(this.state, ["selPers", pid], true);
    this.setState({ selPers: nstate.selPers });
  };

  render() {
    const {
      personsDS,
      sitesToAssign,
      usersToAssign,
      delSite,
      delUser,
      isFetching,
      companyId,
      isSuperAdmin,
      sitePersonsToUnassignObjects,
      assignedSitesDS,
      assignedPersonsDS,
      assignmentList,
      setTableSortKey,
      unassignError,
      bulkAssignOpSuccess,
      userAssignErrorMessage,
      companySites,
      iconFunc,
      storeSiteToUnassign,
      storeSitePersonToUnassign,
      selectSiteFunc,
      undoUnassign,
      activeTab,
      setActiveTab,
      preselectedUser,
      companyPersons,
      companyTypes,
      reset,
      companySubscription,
    } = this.props;

    const { userSearchValue } = this.state;

    const userFields = ["username", "name", "email"];

    return (
      <div>
        {bulkAssignOpSuccess === true ? (
          <div style={{margin: '20px'}}>
            <Alert variant="success">
              Bulk Operation was completed successfully!
            </Alert>
          </div>
        ) : null}
        { !!userAssignErrorMessage && (
            <Alert variant="danger">{userAssignErrorMessage}</Alert>
          )
        }

        <Tabs
          defaultActiveKey={TAB_ASSIGN}
          id="bulk-site-assignment-tabs"
          activeKey={activeTab}
          onSelect={setActiveTab}
        >
          <Tab eventKey={TAB_ASSIGN} title="Bulk Site Assignment">
            {/*
              SITE ASSIGNMENT
           */}

            <div className="BulkAssgn__grid">
              <div />

              <div className="BulkAssgn__SelectSites">
                <h4 className="BulkAssgn__Label">Select Sites</h4>
                <SiteSelector
                  companyId={companyId}
                  showMySitesToggle={!isSuperAdmin}
                />
              </div>

              <div className="BulkAssgn__CentralBar" />

              <div className="BulkAssgn__SitesPool">
                <h4 className="BulkAssgn__Label">Sites to be assigned</h4>
                <ResultPool 
                  resultList={sitesToAssign} 
                  handleDelete={delSite} 
                  iconFunc={getSiteIcons}
                />
              </div>

              <div className="BulkAssgn__SelectUsers">
                <h4 className="BulkAssgn__Label">
                  Select Users (Company or Third-Party)
                </h4>
                <MultiSearchBoxSelector
                  dataSources={personsDS}
                  fields={userFields}
                  minSearchLen={0}
                  closeOnSelect={false}
                  handleSelect={this.handleSelectUser}
                  onChange={this.handleChangeUser}
                  value={userSearchValue || ""}
                  iconFunc={iconFunc}
                  dropdownOpenInitially={true}
                />
              </div>

              <div className="BulkAssgn__UsersPool">
                <h4 className="BulkAssgn__Label">Users to be assigned</h4>
                <ResultPool
                  resultList={usersToAssign}
                  handleDelete={delUser}
                  fieldNames={["username", "name", "email"]}
                  maxWidth={20}
                  iconFunc={getUserIcons}
                />
              </div>

              <div className={"BulkAssgn__EnableCheckboxes"}>
                <WwaCheckbox
                  id={"enable-site-report"}
                  name={"enable-site-report"}
                  onChange={e => this.handleEnableReportAssignment(e)}
                  checked={this.props.enabledReportAssignment}
                />
                &nbsp; Optionally Assign Reports?
              </div>

              <div className={"BulkAssgn__ReportCheckboxes"}>
                <div className={"BulkAssgn__Label"}>Report Type</div>

                <div className={"BulkAssgn__ReportCheckboxes--inner"}>
                  <div className={"BulkAssgn__ReportCheckbox--holder"}>
                    <div className={"BulkAssgn__Label"}>24h Site Reports</div>
                      {
                        companyTypes.includes("winter") && (
                          <BulkSiteReportCheckbox
                            id={"site"}
                            winterType="winter"
                            disabled={
                              isFetching ||
                              !sitesToAssign.length ||
                              !usersToAssign.length ||
                              !this.props.enabledReportAssignment
                            }
                            checked={this.props.reports.site.checked}
                            sendIfNecessary={this.props.reports.site.sendIfNecessary}
                            handleReportCheckboxChange={this.handleReportCheckboxChange}
                            handleReportSubtypeChange={this.handleReportSubtypeChange}
                          />
                        )
                      }
                      {
                        companyTypes.includes("nonwinter") && (
                          <BulkSiteReportCheckbox
                            id={"siteNonwinter"}
                            winterType="nonwinter"
                            disabled={
                              isFetching ||
                              !sitesToAssign.length ||
                              !usersToAssign.length ||
                              !this.props.enabledReportAssignment
                            }
                            checked={this.props.reports.siteNonwinter.checked}
                            sendIfNecessary={this.props.reports.siteNonwinter.sendIfNecessary}
                            handleReportCheckboxChange={
                              this.handleReportCheckboxChange
                            }
                            handleReportSubtypeChange={this.handleReportSubtypeChange}
                          />
                        )
                      }
                  </div>
                  <div className={"BulkAssgn__ReportCheckbox--holder"}>
                    <div className={"BulkAssgn__Label"}>72h Site Reports</div>
                      {
                        companyTypes.includes("winter") && (
                          <BulkSiteReportCheckbox
                            id={"threeDaySummary"}
                            winterType="winter"
                            disabled={
                              isFetching ||
                              !sitesToAssign.length ||
                              !usersToAssign.length ||
                              !this.props.enabledReportAssignment
                            }
                            checked={this.props.reports.threeDaySummary.checked}
                            sendIfNecessary={
                              this.props.reports.threeDaySummary.sendIfNecessary
                            }
                            handleReportCheckboxChange={
                              this.handleReportCheckboxChange
                            }
                            handleReportSubtypeChange={this.handleReportSubtypeChange}
                          />
                        )
                      }
                      {
                        companyTypes.includes("nonwinter") && (
                          <BulkSiteReportCheckbox
                            id={"threeDaySummaryNonwinter"}
                            winterType="nonwinter"
                            disabled={
                              isFetching ||
                              !sitesToAssign.length ||
                              !usersToAssign.length ||
                              !this.props.enabledReportAssignment
                            }
                            checked={this.props.reports.threeDaySummaryNonwinter.checked}
                            sendIfNecessary={
                              this.props.reports.threeDaySummaryNonwinter.sendIfNecessary
                            }
                            handleReportCheckboxChange={
                              this.handleReportCheckboxChange
                            }
                            handleReportSubtypeChange={this.handleReportSubtypeChange}
                          />
                        )
                      }

                      {companySubscription.callToAction &&
                        <BulkSiteReportCheckbox
                          id={"snowtify"}
                          winterType="snowtify"
                          disabled={
                            isFetching ||
                            !sitesToAssign.length ||
                            !usersToAssign.length ||
                            !this.props.enabledReportAssignment
                          }
                          checked={this.props.reports.snowtify.checked}
                          handleReportCheckboxChange={
                            this.handleReportCheckboxChange
                          }
                          handleReportSubtypeChange={this.handleReportSubtypeChange}
                        />
                      }
                  </div>
                </div>
              </div>

              <div className="BulkAssgn__Buttons">
                <Button
                  className="BulkAssgn__CancelButton"
                  onClick={this.handleCancel}
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  onClick={this.handleSubmit}
                  disabled={
                    isFetching || !sitesToAssign.length || !usersToAssign.length
                  }
                >
                  {isFetching ? (
                    <span>
                      <BusySpinner inButton={true} />
                      Assigning Sites
                    </span>
                  ) : (
                    "Assign Sites"
                  )}
                </Button>
              </div>
              <div />
            </div>
          </Tab>

          <Tab eventKey={TAB_UNASSIGN_BY_SITE} title="Unassign By Site">
            <BulkSiteUnassignBySiteTab
              iconFunc={iconFunc}
              sitePersonsToUnassignObjects={sitePersonsToUnassignObjects}
              assignedSitesDS={assignedSitesDS}
              assignedPersonsDS={assignedPersonsDS}
              companySites={companySites}
              unassignError={unassignError}
              isFetching={isFetching}
              selectSiteFunc={selectSiteFunc}
              companyId={companyId}
              storeSiteToUnassign={storeSiteToUnassign}
              storeSitePersonToUnassign={storeSitePersonToUnassign}
              handleSubmitUnassign={this.handleSubmitUnassign}
              handleCancel={this.handleCancel}
              undoUnassign={undoUnassign}
              reset={reset}
            />
          </Tab>

          <Tab eventKey={TAB_UNASSIGN_BY_USER} title="Unassign By User">
            <BulkSiteUnassignByUserTab
              iconFunc={iconFunc}
              sitePersonsToUnassignObjects={sitePersonsToUnassignObjects}
              assignedSitesDS={assignedSitesDS}
              assignedPersonsAndSites={this.props.assignedPersonsAndSites}
              allAssignedPersonsDS={this.props.allAssignedPersonsDS}
              companySites={companySites}
              unassignError={unassignError}
              isFetching={isFetching}
              selectSiteFunc={selectSiteFunc}
              companyId={companyId}
              storeSiteToUnassign={storeSiteToUnassign}
              storeSitePersonToUnassign={storeSitePersonToUnassign}
              handleSubmitUnassign={this.handleSubmitUnassign}
              handleCancel={this.handleCancel}
              undoUnassign={undoUnassign}
              reset={reset}
              personsDS={this.props.personsDS}
              preselectedUser={preselectedUser}
            />
          </Tab>

          <Tab eventKey={TAB_ASSIGNMENTLIST} title="Assignment List">
            <AssignmentPrint
              assignmentList={assignmentList}
              setTableSortKey={setTableSortKey}
              companyId={companyId}
              companyPersons={companyPersons}
              sites={companySites}
              companySubscription={companySubscription}
            />
          </Tab>
        </Tabs>
      </div>
    );
  }
}

export default BulkSiteAssignment;
