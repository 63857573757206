// Client's account
// to not load Aeris map data (e.g. to save network bandwidth, set REACT_APP_NO_AERIS_MAP=true in either .env or .env.local in the root directory
const DEFAULT_AERIS_CLIENT_ID =
  process.env.REACT_APP_AERIS_CLIENT_ID || "icgy89ko1slCRZaQdS4yW";
const DEFAULT_AERIS_CLIENT_PASSWORD =
  process.env.REACT_APP_AERIS_CLIENT_PASSWORD ||
  "ltVnjmfYHFXGq1drbweIQhJ3aWf1INOW11nVFOAI";

export const AERIS_CLIENT_ID = process.env.REACT_APP_NO_AERIS_MAP_CALLS
  ? ''
  : DEFAULT_AERIS_CLIENT_ID;
export const AERIS_CLIENT_PASSWORD = process.env.REACT_APP_NO_AERIS_MAP_CALLS
  ? ''
  : DEFAULT_AERIS_CLIENT_PASSWORD;

// Others
export const frameTimes = Array.apply(null, Array(25))
.map(Number.prototype.valueOf,0)
.map((el,i) => {
    return {
        id : i,
        time : -120 + (i * 5)
    }
});

export const forecastRadarFrameTimes = Array.apply(null, Array(25))
.map(Number.prototype.valueOf,0)
.map((el,i) => {
    return {
        id : i,
        time : (i * 15)
    }
});

export const ANIMATION_PAUSE_LIMIT = 1; //minutes
