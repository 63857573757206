import log from 'loglevel';
import { put, takeEvery, call, select } from 'redux-saga/effects'
import uniqWith from "lodash/uniqWith";
import differenceWith from "lodash/differenceWith";
import reverse from "lodash/reverse";
import fetchApi from 'utils/fetchApi'
import { SUBSCRIBE_REPORT_SUBMIT, SUBSCRIBE_REPORT_SUCCESS, SUBSCRIBE_REPORT_FAILURE,
  SUBSCRIBE_REPORT_UPDATE_SUBMIT, SUBSCRIBE_REPORT_UPDATE_SUCCESS, SUBSCRIBE_REPORT_UPDATE_FAILURE,
  USER_REPORTSUBS_SUBMIT, USER_REPORTSUBS_SUCCESS, USER_REPORTSUBS_FAILURE,
  UNSUBSCRIBE_REPORT_SUCCESS,
  UNSUBSCRIBE_REPORT_SUBMIT, UNSUBSCRIBE_REPORT_FAILURE,
  USER_REPORTSUBS_BY_COMPANY_SUBMIT,
  USER_REPORTSUBS_BY_COMPANY_SUCCESS,
  USER_REPORTSUBS_BY_COMPANY_FAILURE
} from './ReportsDuck'
import { isThirdPartyUser } from "../helpers/helpers";

const compareSiteReportSubscriptions = (a, b) =>
  a.siteId === b.siteId  && a.type === b.type &&
  ((a.personId && b.personId && a.personId === b.personId) ||
    (a.thirdPartyUserId &&
      b.thirdPartyUserId &&
      a.thirdPartyUserId === b.thirdPartyUserId));

function* fetchReportSubscriptionsCall({userId, userType, cbAction}){

  try{
    const authToken = yield select((state) => state.login.authToken);
    let url;
    if (userType === 'person'){
      url = `/Persons/${userId}/reportSubscriptions`;
    } else if (userType === 'third-party-user'){
      url = `/ThirdPartyUsers/${userId}/reportSubscriptions`;
    } else {
      console.log(
        'fetchReportSubscriptionsCall: userType expected to be "person" or "third-party-user", but was:',
        userType
      );
    }

    const subscriptionsParsedJson = yield call(fetchApi, url, {
      authToken,
      method: 'GET'
    })

    if (!subscriptionsParsedJson.error) {

      // make sure that report subscriptions saved later take precedence:
      reverse(subscriptionsParsedJson);

      const uniqueArr = uniqWith(
        subscriptionsParsedJson,
        compareSiteReportSubscriptions
      );

      const diffArr = differenceWith(
        subscriptionsParsedJson,
        uniqueArr
      );
      if(diffArr.length > 0)
        yield call (deleteDuplicateSiteReportSubscriptions, diffArr);

      reverse(uniqueArr);

      yield put({
        type: USER_REPORTSUBS_SUCCESS,
        subsArr : uniqueArr,
        userId,
        userType
      })

    } else 
      yield put({ type: USER_REPORTSUBS_FAILURE,
        message: subscriptionsParsedJson.error.text._error 
          ? subscriptionsParsedJson.error.text._error 
          : subscriptionsParsedJson.error.text
      })

    // inform the caller of this function that we're finished
    if(cbAction)
      yield put({type: cbAction})

  } catch(error){
    log.error('fetchReportSubscriptionsCall error', error)
  }
}

function* deleteDuplicateSiteReportSubscriptions(subsArr) {
  try {
    const authToken = yield select((state) => state.login.authToken);
    for (let sub of subsArr) {

      const url = sub.thirdPartyUserId
        ? `/ThirdPartyUsers/${sub.thirdPartyUserId}/reportSubscriptions/${sub.id}`
        : `/Persons/${sub.personId}/reportSubscriptions/${sub.id}`

      const deleted = yield call(fetchApi, url, {
        authToken,
        method: 'DELETE'
      })
      if (deleted.error && deleted.error.statusCode !== 404) {
        log.error('deleteDuplicateSiteReportSubscriptions Error for ', sub, deleted.error)
      }

    }
    yield;
  } catch (err) {
    log.error("deleteDuplicateSiteReportSubscriptions Error:", err);
  }
}

function* fetchReportSubscriptionsForWholeCompany({companyId}) {
  try {
    const authToken = yield select((state) => state.login.authToken);
    const url = '/ReportSubscriptions'
    const subscriptions = yield call(fetchApi, url, {
      authToken,
      method: 'GET',
      query: {
          filter: JSON.stringify({
            where : {
              companyId : companyId
            }
          })
      }
    })

    if(!subscriptions.error){
      yield put({type: USER_REPORTSUBS_BY_COMPANY_SUCCESS, 
        subscriptions,
        companyId
      })
    } else {
      yield put({ type: USER_REPORTSUBS_BY_COMPANY_FAILURE,
        apiError: subscriptions.error.text
      })
    }
  } catch (error) {
    log.error('fetchReportSubscriptionsForWholeCompany error', error)
    yield put({ type: USER_REPORTSUBS_BY_COMPANY_FAILURE,
      error: 'Network error'
    })
  }
}

function* postReportSubscriptionsCall({userId, companyId, siteId,
  siteReportType, sendIfNecessary, reportType,
  cbAction}){

  try{
    const authToken = yield select((state) => state.login.authToken);
    const companyPersons = yield select((state) => state.companyPersons[companyId]);
    const login = yield select((state) => state.login);

    const user =
      companyPersons
        ? companyPersons.find(u => u.id === userId)
        : login.userId === userId && login
    const url = isThirdPartyUser(user)
      ? `/ThirdPartyUsers/${userId}/reportSubscriptions`
      : `/Persons/${userId}/reportSubscriptions`

    const payload = {companyId}
    if (!siteId){
      payload.type = reportType
    } else {
      payload.type = siteReportType || 'site'
      payload.siteId = siteId
    }
    payload.sendIfNecessary = sendIfNecessary

    const subscriptions = yield call(fetchApi, url, {
      authToken,
      payload,
      method: 'POST'
    })

    if (!subscriptions.error){
      yield put({type: SUBSCRIBE_REPORT_SUCCESS});
    } else {
      yield put({ type: SUBSCRIBE_REPORT_FAILURE,
        message: subscriptions.error.text._error 
          ? subscriptions.error.text._error 
          : subscriptions.error.text
      })
    }

    if(cbAction) yield put (cbAction);
  } catch(error){
    log.error('postReportSubscriptionsCall', error)
  }
}


function* updateReportSubscriptionsCall({userId, companyId, subscriptionId, sendIfNecessary, cbAction}){

  try{
    const authToken = yield select((state) => state.login.authToken);
    const companyPersons = yield select((state) => state.companyPersons[companyId]);
    const login = yield select((state) => state.login);

    const user =
      companyPersons
        ? companyPersons.find(u => u.id === userId)
        : login.userId === userId && login
    const url = isThirdPartyUser(user)
      ? `/ThirdPartyUsers/${userId}/reportSubscriptions/${subscriptionId}`
      : `/Persons/${userId}/reportSubscriptions/${subscriptionId}`

    const payload = {
        subscriptionId,
        sendIfNecessary
    }

    const subscriptions = yield call(fetchApi, url, {
      authToken,
      payload,
      method: 'PATCH'
    })

    if (!subscriptions.error){
      yield put({type: SUBSCRIBE_REPORT_UPDATE_SUCCESS});
    } else {
      yield put({ type: SUBSCRIBE_REPORT_UPDATE_FAILURE,
        message: subscriptions.error.text._error 
          ? subscriptions.error.text._error 
          : subscriptions.error.text
      })
    }

    if(cbAction) yield put (cbAction);
  } catch(error){
    log.warn('updateReportSubscriptionsCall', error)
  }
}

function* deleteReportSubscriptionsCall({userId, companyId, siteId, reportType, cbAction}){

  try{
    const authToken = yield select((state) => state.login.authToken);
    const companyPersons = yield select((state) => state.companyPersons[companyId]);
    const login = yield select((state) => state.login);

    const user =
      companyPersons
        ? companyPersons.find(u => u.id === userId)
        : login.userId === userId && login

    const reportsForUser = yield select((state) => state.userReportSubscriptions[userId]);

    let reportSubs
    switch (reportType) {
      case 'company':
      case 'companyWithSites':
      case 'dailyThreat':
      case 'dailyThreatWithSites':
        reportSubs = reportsForUser.filter(r => r.companyId === companyId && (r.type === reportType))
        break;
      default:
        // site
        reportSubs = reportsForUser.filter(r => r.siteId === siteId && r.type === reportType)
    }

    if (!reportSubs.length) {
      console.warn('DELETing unknown report subscription, user:',user,
        'companyId',companyId,'siteId',siteId)
      yield put({ type: UNSUBSCRIBE_REPORT_FAILURE,
        message: 'Unknown report subscription'
      })
    }

    let errorFound = ''
    // there can be duplicates: delete all that match and ignore 404 errors
    for (let s of reportSubs){
      const url = isThirdPartyUser(user)
        ? `/ThirdPartyUsers/${userId}/reportSubscriptions/${s.id}`
        : `/Persons/${userId}/reportSubscriptions/${s.id}`

      const subscriptions = yield call(fetchApi, url, {
        authToken,
        method: 'DELETE'
      })
      if (subscriptions.error && subscriptions.error.statusCode !== 404) {
        log.error('deleteReportSubscriptionsCall for ', s, subscriptions.error)
        errorFound = subscriptions.error
      }
    }

    if (!errorFound){
      yield put({type: UNSUBSCRIBE_REPORT_SUCCESS});
    } else {
      if(errorFound.text === 'Not found.')
        yield put({type: UNSUBSCRIBE_REPORT_SUCCESS});
      else
        yield put({ type: UNSUBSCRIBE_REPORT_FAILURE,
          message: errorFound
        })
    }

    if(cbAction) yield put (cbAction);

  } catch(error){
    log.error('deleteReportSubscriptionsCall caught', error)
  }
}

export default function* reportSubscriptionSaga () {
  yield takeEvery(USER_REPORTSUBS_SUBMIT, fetchReportSubscriptionsCall)
  yield takeEvery(SUBSCRIBE_REPORT_SUBMIT, postReportSubscriptionsCall)
  yield takeEvery(SUBSCRIBE_REPORT_UPDATE_SUBMIT, updateReportSubscriptionsCall)
  yield takeEvery(UNSUBSCRIBE_REPORT_SUBMIT, deleteReportSubscriptionsCall)
  
  yield takeEvery(USER_REPORTSUBS_BY_COMPANY_SUBMIT, fetchReportSubscriptionsForWholeCompany)
}
