import React from 'react'
import PropTypes from 'prop-types'
import log from 'loglevel'
import memoizeOne from "memoize-one";
import isDeepEqual from "lodash/isEqual";
import './MultiSearchBoxSelector.css'
import chevronUp from 'images/dashboard/w_sort_collapse.svg';
import { matchSearchTerm } from "common/helpers/helpers";

class MultiSearchBoxSelector extends React.Component {

  defaultSeparator = ','

  keyCodeEscape = 27;
  keyCodeEnter = 13;
  keyCodeSpace = 32;

  options = memoizeOne((searchTerm = "", dataSources, fields) => {
    return searchTerm.length >= this.props.minSearchLen // make sure at least X character in input box
      ?  this.createOptionsArray(searchTerm, dataSources, fields)
      : []
  }, isDeepEqual);

  getDropdownOpen = (props, options) =>
    !!(
      Object.keys(options).length &&
      props.minSearchLen === 0 &&
      props.dataSources.some(ds => ds.data.length)
    ) ||
    (!!props.value && props.value.length >= props.minSearchLen);

  constructor(props) {
    super(props)

    const filteredOptions = this.options(props.value, props.dataSources, props.fields);
    const dropdownOpen = this.getDropdownOpen(props, filteredOptions) || props.dropdownOpenInitially;
    this.state = {
      dropdownOpen,
      touched: false,
    }
  }

  // returns selected "option"'s ID value to parent
  handleSelect = (e) => {
    const id = e.target.value
    const ds = this.props.dataSources.find(
      ds => ds.data.find(d => d.id === id)
    )
    const s = ds.data.find(d => d.id === id).name
    this.setState(state => ({
      dropdownOpen: !this.props.closeOnSelect,
      touched: true
    }),
      () => {
        this.props.onChange(this.props.replaceMatch
                ? s.replace(/\t/g, `${this.props.separator || this.defaultSeparator} `)
                : this.props.searchTerm)
        this.props.handleSelect(id)
      })
  }

  // handle key presses within dropdown: ENTER and SPACE will select a value
  handleKeyDown = (e) => {
    if (e.keyCode === this.keyCodeEnter || e.keyCode === this.keyCodeSpace) {
      this.handleSelect(e)
    } else if (e.keyCode === this.keyCodeEscape) {
      this.setState({ dropdownOpen: false })
    }
  }

  // mouse click will select a value
  handleChange = (e) => this.handleSelect(e);

  handleFocus = e =>
    this.setState(state => ({
      dropdownOpen: this.getDropdownOpen(
        this.props,
        this.options(this.props.value, this.props.dataSources, this.props.fields)
      ),
    }));

  closeDropdown = () => this.setState({dropdownOpen: false})

  matches = (dataRecord, keys, searchTerm) => {
    // const keys = this.props.keys
    const terms = searchTerm.split(this.props.separator || this.defaultSeparator).map(s => s.trim())
    const matchers = terms.map(s => matchSearchTerm(s))

    let isMatch = true
    let anyMatch = terms.length === 1

    if (anyMatch) {
      // test searchTerm against all fields denominated by 'keys'
      let fieldsConcatted = ''
      for (let i = 0; i < keys.length; i++) {
        if (!dataRecord[keys[i]]) {
          //log.debug('Key ', keys[i], 'not found in data object', dataRecord)
          continue
        }
        fieldsConcatted += ` ${dataRecord[keys[i]]}`
      }
      isMatch = matchers.some(matches => matches(fieldsConcatted))
    } else {
      // test searchTerms against 'keys' in-order, i.e. searchTerm is split by separator
      // and test searchTerm[1] against 1st key, searchTerm[2] against 2nd key etc
      for (let i = 0; i < keys.length; i++) {
        if (i >= matchers.length) break
        if (!dataRecord[keys[i]]) {
          log.debug('Key ', keys[i], 'not found in data object', dataRecord)
          continue
        }
        isMatch &= matchers[i](dataRecord[keys[i]].trim())
      }
    }
    return isMatch
  }

  handleInputChange = (e) => {
    const s = e.target.value
    this.setState(state => ({
        dropdownOpen: true,
        touched: true,
      }),
      () => this.props.onChange(s)
    )
  }

  // array of data records that match the searchString by keys
  createOptionsArray = (searchString, dataSources, fields) => {
    const optionMap = {}
    dataSources.forEach(dataSource => {
      const data = dataSource.data
      data.filter(d => this.matches(d, dataSource.keys, searchString))
        .map(d => {
          const option = []
          for (let k of fields) {
            if(d[k]) option.push(d[k])
          }
          optionMap[d.id] = option
          return option
        })
    });

    return optionMap
  }

  render() {
    const filteredOptions = this.options(this.props.value, this.props.dataSources, this.props.fields);
    const optionsLength = Object.keys(filteredOptions).length
    return (
      <div className='MultiSearchBoxSelector'>
        <div className='MultiSearchBoxSelector__input--outer'>
          <input value={this.props.value}
            onChange={this.handleInputChange}
            onFocus={this.handleFocus}
            placeholder={this.props.placeHolder || ''}
            className={'MultiSearchBoxSelector__input'}
          />
          {this.state.dropdownOpen && optionsLength > 0 &&
            <img src={chevronUp} alt='Collapse' onClick={this.closeDropdown} />
          }
        </div>

        {this.state.dropdownOpen && optionsLength > 0 &&
          <div className='MultiSearchBoxSelector__select--outer'>
            <select size={Math.min(5, Math.max(optionsLength, 2))}
              onKeyDown={this.handleKeyDown}
              onClick={this.handleChange}
              className='MultiSearchBoxSelector__Select'
            >
              {/* to be able to use onChange() in <select>, make
                  sure nothing useful is selected at the beginning;
                  it seems that the first <option> is selected by React */}
              <option key='-none' value='-none' hidden
                  className='MultiSearchBoxSelector__option'
                >
                  Select one
              </option>
              {Object.keys(filteredOptions).map(id => (
                <option
                  key={id}
                  value={id}
                  className='MultiSearchBoxSelector__option'
                >
                  {this.props.iconFunc && this.props.iconFunc(id)}
                  {' '}
                  {filteredOptions[id].join(`${this.props.separator || this.defaultSeparator} `)}
                </option>
              ))
              }
            </select>
          </div>
        }
        {optionsLength === 0 && this.state.touched &&
          <div className='MultiSearchBoxSelector__nothingFound'>Sorry, nothing found</div>
        }

      </div>
    )
  }
}

MultiSearchBoxSelector.defaultProps = {
  minSearchLen: 1
}

MultiSearchBoxSelector.propTypes = {
  dataSources: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      keys: PropTypes.arrayOf(PropTypes.string).isRequired,
      data: PropTypes.arrayOf(
        PropTypes.shape({id: PropTypes.string.isRequired})
      )
    })
  ).isRequired,
  fields: PropTypes.arrayOf(PropTypes.string).isRequired, // fields out of data to display in dropdown
  placeHolder: PropTypes.string, // input field's placeholder text
  minSearchLen: PropTypes.number, // minimum number of characters that must be input before showing dropdown
  closeOnSelect: PropTypes.bool, // if true: on select, close dropdown list
  replaceMatch: PropTypes.bool, // if true: inject the value selected from dropdown into input field
  handleSelect: PropTypes.func.isRequired, // callback that gets executed when something is selected from list
  onChange: PropTypes.func.isRequired, // callback that gets executed when input box is changed
  value: PropTypes.string.isRequired, // the contents of the input box because this is a controlled component
}

export default MultiSearchBoxSelector
